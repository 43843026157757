import axios from 'axios';
import Cookies from 'universal-cookie';
import Slider from "react-slick";
import ReactDOM from 'react-dom/client';
import React, { useEffect, useState, Fragment, useRef } from 'react';
import { useLocation, useParams, useNavigate, Link } from 'react-router-dom';
import '../sources/styles/stylesProductSingle.css';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { CardProductoPrice } from '../components/CardProductoPrice';
import { CartCheckFill, ShieldCheck, BoxArrowInRight, GeoAlt, Telephone, Truck, Download, CaretRightFill } from 'react-bootstrap-icons';
import { Container, Form, Row, Col, Figure, InputGroup, Button, Modal, Stack, Card, Spinner, ListGroup, Accordion } from "react-bootstrap";

function ProductSingleUjueta() {
    let item_pedido = '';
    const navigate = useNavigate();
    const Location = useLocation();
    const params = useParams();
    const sliderRef = useRef(null);
    const tipoCliente = params.item_pedido.split('/')
    const [titulo, setTitulo] = useState('');
    const [showMensaje, setShowMensaje] = useState(false);
    const [datosProducto, setDatosProducto] = useState([]);
    const [itemsRecommendation, setItemsRecommendation] = useState([]);
    const [cantidad, setCantidad] = useState(1);
    const [imagenBanner, setImagenBanner] = useState([]);
    const [imagenBanner3, setImagenBanner3] = useState([]);
    const imagenPublicidad = process.env.REACT_APP_SERVER_IMAGE + 'estaticas/';
    const imagenBanner2 = process.env.REACT_APP_SERVER_IMAGE + 'estaticas/baner_principal-small2.webp';

    const formatoPesosColombianos = new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0
    });

    const settingsBanners = {
        autoplay: true,
        speed: 500,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const settings2 = {
        dots: false,
        infinite: true,
        speed: 500,
        autoplay: true,
        slidesToShow: 2,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const queryTokenNuevo = async (item, tipo_cliente) => {
        const cookies = new Cookies();
        try {
            const ip = await consultarIp();
            const url = process.env.REACT_APP_SERVER + '/api/v1/token/productSingle';
            const body = { item_pedido: item, tipo_cliente: tipo_cliente, ipUsuario: ip };
            const { data } = await axios.post(url, body);
            cookies.set('accesToken', data.accessToken, { path: '/' })
        } catch (error) {
            console.log('Error en la consulta de token productSingle: ' + error.message);
        }
    }

    const consultarIp = async () => {
        let ip = ''
        try {
            const url = 'https://www.cloudflare.com/cdn-cgi/trace';
            const { data } = await axios.get(url);
            ip = data.split('\n')
            ip = ip[2].split('=')
            ip = ip[1];
        } catch (err) {
            const url2 = 'https://geolocation-db.com/json/';
            const { data } = await axios.get(url2);
            ip = data.IPv4;
        }
        return ip
    }

    if (Location.state === null) {
        if (tipoCliente[1] === 'B2B' || tipoCliente[1] === 'DETAL') {
            navigate('/inicioSesion', { state: { page: '/productSingleUjueta', item_pedido: tipoCliente[0] } });
        } else if (tipoCliente[1] === 'B2C') {
            item_pedido = tipoCliente[0]
            queryTokenNuevo(tipoCliente[0], tipoCliente[1]);
        } else {
            item_pedido = tipoCliente[0]
        }
    } else {
        item_pedido = Location.state.item_pedido
    }

    let [objItem, setObjItem] = useState({
        item: item_pedido,
        um: 'UND',
        cantidad: 1,
        precio: '',
        descuento_original: '',
        descuento: '',
        valor_final: ''
    });

    const queryAgregarCarrito = async () => {
        validationRoute();
        const cookies = new Cookies();
        const accessToken = cookies.get('accesToken');
        const user = cookies.get('user');
        if (cantidad !== '') {
            try {
                const urlRegistrar = process.env.REACT_APP_SERVER + '/api/v1/shoppingCart/register';
                const body = { cantidad, objItem, sobrePedido: 'SI' , lugar: 'productSingleUjueta'};
                await axios.post(urlRegistrar, body, {
                    headers: {
                        "Authorization": `Bearer ${accessToken}`
                    }
                });
                setShowMensaje(true)
                setCantidad(1);
                const urlCarrito = process.env.REACT_APP_SERVER + '/api/v1/shoppingCart/list';
                const responseCarrito = await axios.get(urlCarrito, {
                    headers: {
                        "Authorization": `Bearer ${accessToken}`
                    }
                });
                const dataCarrito = responseCarrito.data[0];
                cookies.set('carrito', (dataCarrito.length));
                if (user.tipo_usuario === 'temporal') {
                    const root = ReactDOM.createRoot(
                        document.getElementById('numberCarTemporal')
                    );
                    const element = <span className="badge badge-dark">{cookies.get('carrito')}</span>
                    root.render(element);
                } else {
                    const root = ReactDOM.createRoot(
                        document.getElementById('numberCar')
                    );
                    const element = <span className="badge badge-dark">{cookies.get('carrito')}</span>
                    root.render(element);
                }
            } catch (error) {
                console.log(error)
            }
        } else {
            alert("La cantidad debe ser diferente de vacio")
        }
    }

    const validationRoute = async () => {
        const cookies = new Cookies();
        const accessToken = cookies.get('accesToken');
        try {
            const url = process.env.REACT_APP_SERVER + "/requireAuth/routes";
            await axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
        } catch (error) {
            console.log('La validación presentó error')
            console.log(error);
            cookies.remove('accessToken', { path: "/" });
            cookies.remove('username', { path: "/" });
            cookies.remove('id', { path: "/" });
            window.location.href = "/inicioSesion";
        }
    }

    const handleCantidad = (e) => {
        if (e.target.value % 1 === 0) {
            if (e.target.value === '0') {
                alert('La cantidad debe ser diferente de 0');
            } else {
                if (e.target.value < 0) {
                    alert('La cantidad debe ser mayor a 0');
                } else {
                    setCantidad(e.target.value)
                    setObjItem({
                        ...objItem,
                        cantidad: e.target.value
                    });
                }
            }
        } else {
            alert('La cantidad debe de ser un número entero')
        }
    }

    const validacionInicial = async () => {
        const cookies = new Cookies();
        const accessToken = cookies.get('accesToken');
        if (accessToken === undefined) {
            await queryTokenNuevo(item_pedido, 'B2C').then(() => {
                window.location.reload(true);
            });
        } else {
            setCantidad(1);
            try {
                const urlCliente = process.env.REACT_APP_SERVER + '/api/v1/validarListaPrecio';
                const respCliente = await axios.get(urlCliente, {
                    headers: {
                        "Authorization": `Bearer ${accessToken}`
                    }
                });
                if (respCliente.data.tipo_cliente_pagina === 'CATALOGO') {
                    navigate('/inicioSesion', { state: { page: '/productSingleUjueta', item_pedido: tipoCliente[0] } });
                } else {
                    const urlUjueta = 'https://api-clientes.ujueta.com/products/sku/' + item_pedido;
                    const resultDatos = await axios.get(urlUjueta, {
                        headers: {
                            'accept': '*/*',
                            'api_key': '544a2a20-44ef-46d2-a434-6b313d7362f0',
                        },
                    });
                    const { data, status } = resultDatos;
                    if (status === 200) {
                        const resultUjueta = data.data[0].attributes;
                        let descuento = 0;
                        if (respCliente.data.lista_de_precios === '001') {
                            const { data: resultCategoria } = await axios.get(process.env.REACT_APP_SERVER + '/api/v1/items/ujueta/find/' + item_pedido, {
                                headers: {
                                    "Authorization": `Bearer ${accessToken}`
                                }
                            });
                            if (resultCategoria.result && resultCategoria.producto[0].descuento_categoria !== null) {
                                descuento = resultCategoria.producto[0].descuento_categoria;
                            }
                        }
                        setDatosProducto([
                            {
                                ...resultUjueta,
                                descuento: 0,
                                referencia: data.sku,
                                precio: parseInt((data.price * 1.19) * (1 - parseFloat(descuento) / 100)),
                                precioAntes: parseInt((data.price * 1.19) * (1 - parseFloat(descuento) / 100)),
                                onhand: data.Onhand
                            }
                        ]);
                        setObjItem({
                            ...objItem,
                            cantidad: 1,
                            precio: parseInt((data.price * 1.19) * (1 - parseFloat(descuento) / 100)),
                            descuento: 0,
                            descuento_original: 0,
                            descripcion: data.label,
                            valor_final: parseInt((data.price * 1.19) * (1 - parseFloat(descuento) / 100))
                        });
                    }
                }
                const [bannerResponse, banner3Response, recomendacionResponse] = await Promise.all([
                    axios.post(`${process.env.REACT_APP_SERVER}/api/v1/products/banner`, { pagina: 'productSingle' }, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }),
                    axios.post(`${process.env.REACT_APP_SERVER}/api/v1/products/banner`, { pagina: 'productSingle3' }, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }),
                    axios.get(`${process.env.REACT_APP_SERVER}/api/v1/items/recommendation/20769`, {
                        headers: {
                            'Authorization': `Bearer ${accessToken}`
                        },
                    })
                ]);
                setImagenBanner(bannerResponse.data);
                setImagenBanner3(banner3Response.data);
                setItemsRecommendation(recomendacionResponse.data.values);
                setTitulo(recomendacionResponse.data.titulo);
            } catch (error) {
                console.error(error);
            }
        }
    }

    useEffect(() => {
        validacionInicial();
        // eslint-disable-next-line
    }, [item_pedido, Location]);

    return (
        <>
            <Header />
            {datosProducto ?
                (datosProducto.length > 0 ?
                    (<Container>
                        <Row xs={1} sm={2}>
                            <Col>
                                <Stack>
                                    <div style={{ position: 'relative' }}>
                                        <Slider ref={sliderRef} {...settingsBanners} >
                                            {
                                                datosProducto[0].galeria_de_fotos !== undefined && (
                                                    datosProducto[0].galeria_de_fotos.map((imagen, id) =>
                                                        <Figure key={id} id={id} >
                                                            <Figure.Image
                                                                width={500}
                                                                height={800}
                                                                alt="imagenPrudct"
                                                                src={imagen.url}
                                                            />
                                                        </Figure>
                                                    )
                                                )
                                            }
                                        </Slider>
                                        <Button className='btnAdicionar' variant="danger" style={{ position: 'absolute', top: 0, left: 0 }}><b>BAJO PEDIDO</b></Button>
                                    </div>
                                    <div>
                                        {
                                            datosProducto[0].galeria_de_fotos !== undefined &&
                                            datosProducto[0].galeria_de_fotos.map((imagen, i) =>
                                                <Figure key={i}  >
                                                    <Figure.Image
                                                        width={75}
                                                        height={75}
                                                        alt="variant"
                                                        onClick={() => { sliderRef.current.slickGoTo(i) }}
                                                        src={imagen.thumbnail}
                                                    />
                                                </Figure>
                                            )
                                        }
                                    </div>
                                </Stack>
                            </Col>
                            <Col style={{ textAlign: 'center' }}>
                                <h1 style={{ textTransform: 'uppercase' }}>{datosProducto[0].nombre_en_colombia}</h1>
                                <h6><b>Referencia: </b>{datosProducto[0].sap_itemcode} <b>Codigo: </b>{datosProducto[0].referencia} <b>Marca:</b> {datosProducto[0].marca}</h6>
                                <hr />
                                <div>
                                    <h5>✅<b>{datosProducto[0].descripcion}</b></h5>
                                </div>
                                <div style={{ textAlign: 'center' }}>
                                    <div className='text_precio'>
                                        <h1 className='text-danger display-1'>
                                            <b>{formatoPesosColombianos.format(datosProducto[0].precioAntes)}</b>
                                        </h1>
                                        <small> IVA incluido</small>
                                    </div>
                                </div>
                                <Col xs={12} className='text-danger'>
                                    <Truck /><strong> Entrega en 7 días hábiles</strong>
                                </Col>
                                <div className='div_caracteristicas'>
                                    {
                                        datosProducto[0].onhand === 0
                                            ?
                                            <label><b>Disponibilidad: <span style={{ color: 'red' }}>VALIDAR DISPONIBILIDAD</span></b></label>
                                            :
                                            <div>
                                                <label><b>Disponibilidad:</b> {datosProducto[0].onhand} UND</label>
                                                <br></br>
                                                <h4>🔥<b>Compralo ahora,este producto se vende rápido</b></h4>
                                            </div>
                                    }
                                </div>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        type="number"
                                        aria-label="Example text with button addon"
                                        aria-describedby="basic-addon1"
                                        min="1"
                                        value={cantidad}
                                        onChange={handleCantidad}
                                    />
                                    <Form.Select size="sm">
                                        <option value='UND'>UND</option>
                                    </Form.Select>
                                    <Button className='btnAdicionar' variant="outline-warning" id="button-addon1" disabled={datosProducto[0].onhand <= 0} onClick={() => queryAgregarCarrito()}>
                                        <CartCheckFill /><strong> Agregar al carrito</strong>
                                    </Button>
                                </InputGroup>
                                {
                                    imagenBanner3.map((fila, id) =>
                                        <div key={id} >
                                            <a href={fila.telefono} target={'_new'}>
                                                <img src={imagenPublicidad + fila.pagina} alt="bannerPrincipal" style={{ maxHeight: 'max-content' }} id='Banner' />
                                            </a>
                                        </div>
                                    )
                                }
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col md={6}>
                                <h4 className='title'>Información Adicional</h4>
                                <div style={{ textAlign: 'justify !important' }}>
                                    <Row className='d-flex justify-content-center mb-3'>
                                        {
                                            datosProducto[0].ficha_tecnica &&
                                            <Col xs={12} md={5} className='border p-4 px-5 mx-1 rounded' style={{ background: '#F5F5F5', border: '#DADADA' }}>
                                                <h6 className="mb-4 text-center">Ficha de producto</h6>
                                                <Button onClick={() => window.open(datosProducto[0].ficha_tecnica.url, '_blank')} variant='warning' style={{ height: '52px', width: '52px' }} className="d-flex m-auto text-center rounded-circle">
                                                    <Download className="m-auto w-full" />
                                                </Button>
                                            </Col>
                                        }
                                        {
                                            datosProducto[0].manual &&
                                            <Col xs={12} md={5} className='border p-4 px-5 mx-1 rounded' style={{ background: '#F5F5F5', border: '#DADADA' }}>
                                                <h6 className="mb-4 text-center">Manual de usuario</h6>
                                                <Button onClick={() => window.open(datosProducto[0].manual.url || '', '_blank')} variant='warning' style={{ height: '52px', width: '52px' }} className="d-flex m-auto text-center rounded-circle">
                                                    <Download className="m-auto w-full" />
                                                </Button>
                                            </Col>
                                        }
                                    </Row>
                                    {
                                        datosProducto[0].videopresentacion &&
                                        <div className="mb-4">
                                            <h5 className="mb-4 text-justify">Video presentación</h5>
                                            
                                            <div style={{ position: "relative", paddingBottom: "56.25%", height: 0, overflow: "hidden" }}>
                                                <iframe
                                                    src={`https://www.youtube.com/embed/${datosProducto[0].videopresentacion.split("/").pop()}`}
                                                    style={{
                                                    position: "absolute",
                                                    top: 0,
                                                    left: 0,
                                                    width: "100%",
                                                    height: "100%",
                                                    }}
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowFullScreen
                                                    title="YouTube Video"
                                                ></iframe>
                                                
                                            </div>
                                        </div>
                                    }
                                    {
                                        datosProducto[0].especificaciones_tecnicas &&
                                        <ListGroup className="mb-4">
                                            <h5 className="mb-4 text-justify">Especificaciones técnicas</h5>
                                            {
                                                datosProducto[0].especificaciones_tecnicas.split(';').map((especificacion, e) =>
                                                    especificacion.trim() !== '' && <ListGroup.Item key={e}>{especificacion.trim()}</ListGroup.Item>
                                                )
                                            }
                                        </ListGroup>
                                    }
                                    <Accordion defaultActiveKey="0">
                                        {
                                            datosProducto[0].caracteristicas &&
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header style={{ border: '1px solid #DADADA', borderRadius: '5px' }}><h5>Características</h5> <CaretRightFill /></Accordion.Header>
                                                <Accordion.Body>
                                                    <ListGroup variant="flush">
                                                        {
                                                            datosProducto[0].caracteristicas.split(';').map((especificacion, e) =>
                                                                <ListGroup.Item key={e}>{especificacion.trim()}</ListGroup.Item>
                                                            )
                                                        }
                                                    </ListGroup>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        }
                                        {
                                            datosProducto[0].beneficios &&
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header style={{ border: '1px solid #DADADA', borderRadius: '5px' }}><h5>Beneficios</h5><CaretRightFill /></Accordion.Header>
                                                <Accordion.Body>
                                                    <ListGroup variant="flush">
                                                        {
                                                            datosProducto[0].beneficios.split(';').map((especificacion, e) =>
                                                                <ListGroup.Item key={e}>{especificacion.trim()}</ListGroup.Item>
                                                            )
                                                        }
                                                    </ListGroup>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        }
                                        {
                                            datosProducto[0].incluye &&
                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header style={{ border: '1px solid #DADADA', borderRadius: '5px' }}><h5>Incluye</h5><CaretRightFill /></Accordion.Header>
                                                <Accordion.Body>
                                                    <ListGroup variant="flush">
                                                        {
                                                            datosProducto[0].incluye.split(';').map((especificacion, e) =>
                                                                <ListGroup.Item key={e}>{especificacion.trim()}</ListGroup.Item>
                                                            )
                                                        }
                                                    </ListGroup>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        }
                                        {
                                            datosProducto[0].tecnologia &&
                                            <Accordion.Item eventKey="3">
                                                <Accordion.Header style={{ border: '1px solid #DADADA', borderRadius: '5px' }}><h5>Tecnologia</h5><CaretRightFill /></Accordion.Header>
                                                <Accordion.Body>
                                                    <ListGroup variant="flush">
                                                        {
                                                            datosProducto[0].tecnologia.split(';').map((especificacion, e) =>
                                                                <ListGroup.Item key={e}>{especificacion.trim()}</ListGroup.Item>
                                                            )
                                                        }
                                                    </ListGroup>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        }
                                    </Accordion>
                                </div>
                            </Col>
                            <Col md={6}>
                                <h3>{titulo}</h3>
                                <Slider {...settings2}>
                                    {
                                        itemsRecommendation.map((item, i) =>
                                            <Fragment key={i}>
                                                <CardProductoPrice propiedades={item} />
                                            </Fragment>
                                        )
                                    }
                                </Slider>
                                <br></br>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className='border border-warning pb-3' style={{ textAlign: 'center' }}>
                                    <h1 className='bg-warning pb-3 display-2'><b><ShieldCheck /> ¿Este producto tiene garantía?</b></h1>
                                    <h1><b style={{ color: '#198754' }}>Todos nuestros productos son originales</b> cuentan con garantía, además de <b style={{ color: '#198754' }}>COMPRA PROTEGIDA.</b></h1>
                                    <br></br>
                                    <h1>Recibirás una confirmación de pedido y detalles de envío en tu correo electrónico.</h1>
                                    <h1>Recuerda que realizamos envíos a nivel nacional y tenemos diferentes medios de pago</h1>
                                    <br></br>
                                    <Link to='/registrarUsuario' state={{ page: '/' }}><Button variant="dark"><h1><BoxArrowInRight /> Regístrate para mantenerte al tanto de futuros descuentos</h1></Button></Link>
                                </div>
                                <br></br>
                                {
                                    imagenBanner.map((fila, id) =>
                                        <div key={id} style={{ width: '100%', maxWidth: '100%', height: '30%', textAlign: 'center' }}>
                                            <a href={fila.telefono} target={'_new'}>
                                                <img src={imagenPublicidad + fila.pagina} alt="bannerPrincipal" />
                                            </a>
                                        </div>
                                    )
                                }
                            </Col>
                        </Row>
                        <a className='btn-wsp' target={"_new"} href={`https://wa.me/${datosProducto[0].numero_whatsApp}?text=Hola, me encuentro en la página web de la FERRETERIA MASTER y quisiera conocer más información sobre el producto ${datosProducto[0].descripcion_alternativa}, ítem ${datosProducto[0].item} con referencia ${datosProducto[0].referencia}`} aria-label="Número de whatsApp de nuestros asesores">
                            <Card.Img className='btnWsp1' alt="75x75" src={process.env.REACT_APP_SERVER_IMAGE + 'estaticas/whatsapp1.webp'} />
                        </a>
                    </Container>)
                    :
                    (<>
                        <div className="d-flex justify-content-center" style={{ height: '35vh', alignItems: 'center' }}>
                            <Spinner animation="border" variant="warning" style={{ width: '100px', height: '100px' }} />
                        </div>
                    </>))
                :
                (<Container><h3>Lo sentimos, este producto ya no está disponible</h3>
                    <Row style={{ alignItems: 'center' }}>
                        <Col sm={7}>
                            <Row className="m-3 p-3 mb-0">
                                <Col sm={12}>
                                    <h3>Contáctenos</h3>
                                </Col>
                                <Col sm={12}>
                                    <Card>
                                        <Card.Header>Sede Buga</Card.Header>
                                        <Card.Body>
                                            <blockquote className="blockquote mb-0">
                                                <small>
                                                    <GeoAlt />
                                                    Dirección: CALLE 7 10 50
                                                    <br></br>
                                                    <Telephone />
                                                    Teléfono: 2363000
                                                </small>
                                            </blockquote>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            <Row className="m-3 p-3 mb-0">
                                <Col sm={12}>
                                    <Card>
                                        <Card.Header>Sede Palmira</Card.Header>
                                        <Card.Body>
                                            <blockquote className="blockquote mb-0">
                                                <small>
                                                    <GeoAlt />
                                                    Dirección: CARRERA 33A 28 24
                                                    <br></br>
                                                    <Telephone />
                                                    Teléfono: 2660066
                                                </small>
                                            </blockquote>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            <Row className="m-3 p-3 mb-0">
                                <Col sm={12}>
                                    <Card>
                                        <Card.Header>Sede Tuluá</Card.Header>
                                        <Card.Body>
                                            <blockquote className="blockquote mb-0">
                                                <small>
                                                    <GeoAlt />
                                                    Dirección: CARRERA 40 33 21
                                                    <br></br>
                                                    <Telephone />
                                                    Teléfono:2339733
                                                </small>
                                            </blockquote>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={5} className="m-auto m-3 p-3 ">
                            <div style={{ textAlign: 'center' }}>
                                <img src={imagenBanner2} style={{ width: "100%", height: "100%" }} alt="product" className="img-fluid" />
                            </div>
                        </Col>
                    </Row>
                </Container>)
            }
            <Modal show={showMensaje} onHide={() => setShowMensaje(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Se agregó al carrito</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button id="btnProductSingleAceptar" variant="primary" onClick={() => setShowMensaje(false)}>
                        Aceptar
                    </Button>
                </Modal.Footer>
            </Modal>
            <Footer />
        </>
    )
}

export { ProductSingleUjueta };